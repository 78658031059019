<template>
    <section>
        <div class="row mx-0 py-2 align-items-center">
            <div class="col-auto">
                <div class="bg-general3 text-white d-middle-center br-50 cr-pointer" style="width:25px;height:25px;" @click="irAtras">
                    <i class="icon-left f-11" />
                </div>
            </div>
            <div class="col text-general f-15">
                Editar Cupón
            </div>
        </div>
        <tabs :tabs="rutasTabs" class="border-bottom" />
        <div id="cont-scroll" class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 163px)">
            <router-view />
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {}
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
        rutasTabs(){
            let rutas = [
                {
                    titulo: 'Información',
                    ruta: 'admin.cupones.editar.informacion'
                }
            ]
            if(this.datosBasicos.destinatario === 1){
                rutas.push({
                    titulo: `${this.$config.cliente} específicos`,
                    ruta: 'admin.cupones.editar.clientes-especificos'
                })
            }
            if(this.datosBasicos.destinatario === 2){
                rutas.push({
                    titulo: `${this.$config.vendedor} específicos`,
                    ruta: 'admin.cupones.editar.clientes-especificos'
                })
            }

            return rutas;
        }
    },
    mounted(){
        //this.moverAbajo();
    },
    methods:{
        irAtras(){
            if(this.datosBasicos.destinatario === 1){
                this.$router.push({name: 'admin.cupones.clientes.lista'})
                return
            }
            if(this.datosBasicos.destinatario === 2){
                this.$router.push({name: 'admin.cupones.lechero.lista'})
                return
            }
        }
    }
}
</script>
<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
</style>
